<template>
  <div :style="{ height: screeHeight + 'px' }" class="wrap">
    <div :style="{ height: screeHeight - 60 + 'px' }" style="overflow: auto">
      <div v-for="ite in carts" :key="ite.id" class="cart-list">
        <van-cell>
          <template #title>
            <strong style="font-size: 18px">{{ ite.productName }}</strong>
            <van-checkbox
              v-model="ite.isPermitRefund"
              checked-color="#07c160"
              style="float: right; color: lime; margin-top: 5px"
              >可退款</van-checkbox
            >
          </template>
        </van-cell>
        <van-cell>
          <template #title>
            <span v-if="ite.isPermitRefund" class="refun">此产品可退款</span>
            <span v-if="ite.isPermitRefund" class="refunPrice"
              >退票手续费：0元</span
            >
            <span style="float: right; color: #ff5345; font-size: 25px"
              >￥{{ getSum() }}
              <span v-if="ite.delPrice" class="del-price"
                >￥{{ ite.delPrice }}</span
              ></span
            >
          </template>
        </van-cell>
        <van-cell>
          <template #title>
            <div>数 量：</div>
          </template>
          <div style="" class="cart-line1">
            <div @click.stop="out(ite)">
              <van-icon
                name="minus"
                style="line-height: 15px; margin-top: 5px"
              />
            </div>
            <input class="inp" v-model="ite.count" @change="inputNum(ite)" />
            <div @click.stop="add(ite)">
              <van-icon
                name="plus"
                style="line-height: 15px; margin-top: 5px"
              />
            </div>
          </div>
        </van-cell>
        <van-cell
          style="corlor: #666666"
          @click="toProductInfo(ite.productId)"
          :title="ite.validEndDate + '前使用有效'"
          is-link
          value="购买须知"
        />
        <van-row style="padding: 5px 16px; background-color: white">
          <van-col span="7" style="line-height: 40px; corlor: #333333"
            ><strong>游玩日期</strong></van-col
          >
          <van-col span="17" @click="toShowDate(ite)"
            ><span class="visit-date">{{ ite.visitDate }}</span></van-col
          >
        </van-row>
        <van-row
          v-if="ite.appointment"
          style="padding: 5px 16px; background-color: white"
        >
          <van-col span="5" style="line-height: 40px"
            ><strong>预约时间</strong></van-col
          >
          <van-col span="19">
            <van-col
              span="11"
              v-for="(it, index) in ite.appointment"
              :key="it.id"
              @click="toCheckTime(ite.appointment, index)"
              style="text-align: center; margin: 0 2px"
            >
              <div
                :style="
                  it.check
                    ? 'border: 1px solid #ff976a'
                    : 'border: 1px solid #c8c9cc'
                "
                class="hasStock"
              >
                {{ `${it.startTime} - ${it.endTime}` }}
                <div v-if="it.stock - it.saledStock > 0">尚有余票</div>
                <div v-else>已无票</div>
              </div>
            </van-col>
          </van-col>
        </van-row>
        <!-- v-if="ite.orderFields.length>0" -->
        <div v-if="ite.orderFields.length > 0">
          <van-row style="padding: 16px; background-color: white">
            <van-col span="4"><strong>出游人</strong></van-col>
            <van-col span="17">
              <span style="font-size: 10px; color: #999999"
                >需要填写
                {{ ite.playerRule === 0 ? ite.count : ite.playerRule }}
                位出游人信息</span
              >
            </van-col>
          </van-row>
          <van-row type="flex" style="padding: 16px; background-color: white">
            <span
              v-for="(item, index) in ite.chuyourens"
              :key="item.id"
              class="chuyouname"
              :class="item.select ? 'selectChuyouname' : 'chuyouname'"
              @click="selectChuyouren(index, ite, '')"
              >{{ item.playerName }}</span
            >
            <span class="chuyouname" @click="goAddChuYouRen">新增 ></span>
          </van-row>
          <van-row
            v-for="item in ite.chuyourens"
            :key="item.id"
            class="chuyouren-wrap"
          >
            <!-- <van-col span="2" style="margin-top: 7%;height: 35px;" @click="selectChuyouren(index,ite,'out')"><van-icon name="clear" /></van-col> -->
            <van-col span="19">
              <div style="">{{ privacyName(item.playerName) }}</div>
              <div style="">手机号：{{ privacyStr(item.mobile) }}</div>
              <div style="">身份证：{{ privacyStr(item.idcardNumber) }}</div>
            </van-col>
            <!-- <van-col span="4">
              <div
                :style="{
                  color:
                    item.ankang == 1
                      ? '#07c160'
                      : item.ankang == 2
                      ? '#ffd01e'
                      : 'ee0a24',
                }"
                class="ankang-info"
              >
                <van-icon
                  class-prefix="erweima"
                  name="extra"
                  class="ankangma"
                  style="font-size: 40px !important"
                />
                <div style="color: #333333; font-size: 12px">安康码</div>
              </div>
            </van-col> -->
            <van-col span="1" style="color: #ef8669; font-size: 20px">
              <van-icon
                name="edit"
                @click="toEditChuyouren(item.id)"
                class="chuyouren-edit"
              />
            </van-col>
          </van-row>
        </div>
      </div>
      <van-row class="buy-user">
        <strong style="font-size: 18px">购买人信息</strong>
      </van-row>
      <van-row style="background-color: white; padding: 5px 10px 16px 1px">
        <van-field v-model="user.name" label="姓名" placeholder="请输入姓名" />
        <van-field
          v-model="user.mobile"
          required
          label="手机号"
          placeholder="请输入手机号"
        />
        <!-- <div>张三</div>
        <div>手机号：18956454897</div> -->
      </van-row>
      <div style="" class="order-price">
        <strong style="font-size: 18px">订单金额</strong>
        <div style="color: #ff5345; font-weight: bold">￥{{ getSum() }}</div>
      </div>
    </div>

    <van-button
      round
      type="danger"
      @click="save"
      style="width: 97%; margin-left: 1.5%; position: fixed; bottom: 6px"
      >确定</van-button
    >
    <!-- 游玩日期预约 -->
    <van-calendar
      v-model="showDate"
      @confirm="onConfirm"
      :formatter="formatter"
      @month-show="monthShow"
      color="#07c160"
    />
    <!-- 正常可选 -->
    <van-calendar
      v-model="canDate"
      :min-date="minDate"
      :max-date="maxDate"
      @confirm="onConfirm"
      :formatter="formatter"
      @month-show="monthShow"
      color="#07c160"
    />
    <!-- 支付方式 -->
    <van-popup
      v-model="isPay"
      @click-overlay="noPay"
      @click-close-icon="noPay"
      closeable
      round
      position="bottom"
      :style="{ paddingBottom: '20px' }"
    >
      <div style="padding: 15px">
        订单金额
        <span style="color: #ff6034; font-size: 22px; font-weight: bold"
          >￥{{ comOrder.payAmount }}</span
        >
      </div>
      <!-- 先去除 微信和支付宝支付两个按钮 -->
      <!-- <div @click="toPay('trade.h5Pay')" style="line-height: 35px;font-size: 20px;padding: 5px 10px;border-top: 3px solid #ebedf0;">
        <span style="font-size: 22px;color: blue;padding-right: 5px;"><van-icon name="alipay" /></span>支付宝</div>
      <div @click="toPay('WXPay.h5Pay')" style="line-height: 35px;font-size: 20px;padding: 5px 10px;border-top: 1px solid #ebedf0;">
        <span style="font-size: 22px;color: #07c160;padding-right: 5px;"><van-icon name="wechat" /></span>微信</div> -->
      <div
        @click="toPay('uac.order')"
        style="
          line-height: 35px;
          font-size: 20px;
          padding: 5px 10px;
          border-top: 1px solid #ebedf0;
          border-bottom: 1px solid rgb(235, 237, 240);
        "
      >
        <span style="font-size: 22px; color: #ff6034; padding-right: 5px"
          ><van-icon name="card" /></span
        >银联云闪付
      </div>
    </van-popup>
    <!-- 健康码 -->
    <van-dialog
      v-model="showQrCode"
      theme="round-button"
      title="非绿码人员不可购买"
    >
      <!-- <img style="width:100%" src="https://img01.yzcdn.cn/vant/apple-3.jpg" /> -->
      <van-row
        v-for="(item, index) in ankangs"
        :key="index"
        style="padding: 16px; background-color: white; color: #797576"
      >
        <van-col span="20">
          <div style="">{{ item.playerName }}</div>
          <!-- <div style="">手机号：{{ item.mobile }}</div> -->
          <div style="">身份证：{{ item.idcardNumber }}</div>
        </van-col>
        <van-col span="4">
          <div
            :style="{
              color:
                item.ankang == 1
                  ? '#07c160'
                  : item.ankang == 2
                  ? '#ffd01e'
                  : 'ee0a24',
            }"
            style="float: right; font-size: 40px"
          >
            <van-icon
              class-prefix="erweima"
              name="extra"
              style="font-size: 35px"
            />
          </div>
        </van-col>
      </van-row>
    </van-dialog>
  </div>
</template>
<script>
  import {
    dateFormat,
    getCurrentMonthLast,
    privacyStr,
    privacyName,
  } from "../../utils";
  import { Toast } from "vant";
  export default {
    name: "info",
    data() {
      return {
        privacyStr,
        privacyName,
        screenWidth: document.documentElement.clientWidth, // 屏幕宽
        screeHeight: document.documentElement.clientHeight, // 屏幕高
        chuyourens: [],
        showcChuyourens: [],
        active: 0,
        info: {},
        checked: true,
        showDate: false,
        orders: [],
        carts: [],
        selectChuyourens: [],
        order: {},
        user: this.$store.state.user,
        checkProduct: {},
        times: [],
        isAppointment: false, //是否预约票
        payUrl: "",
        comOrder: {}, //下单成功返回
        isPay: false, //弹出支付
        showQrCode: false, //弹出健康码
        ankangs: [],
        canDate: false,
        minDate: new Date(),
        maxDate: new Date(),
        organizationId: "",
      };
    },
    created() {
      this.organizationId = this.$route.query.id || "";
    },
    mounted() {
      // this.checkAnKangMa()
      let arr =
        this.$store.state.orders && this.$store.state.orders.length > 0
          ? this.$store.state.orders
          : JSON.parse(localStorage.getItem("orders"));
      this.isAppointment = this.$route.query.isAppointment;
      if (this.isAppointment && this.isAppointment != "false") {
        //预定
        arr.productList = [];
        arr.productList.push(arr);
      }
      this.carts = arr.productList;
      this.order = arr ? JSON.parse(localStorage.getItem("orders")) : arr;
      this.getChuyouren(); //获取已有出游人
    },
    methods: {
      goAddChuYouRen() {
        let arr = [];
        this.chuyourens.forEach((item) => {
          arr.push(item.idcardNumber);
        });
        this.$router.push({
          path: "/personal/addChuyouren",
          query: {
            arr,
          },
        });
      },
      getSum() {
        let sum = 0;
        this.carts.map((ite) => {
          sum += ite.count * ite.salePrice;
        });
        return sum.toFixed(2);
      },
      inputNum(ite) {
        if (ite.count < 2) {
          ite.count = 1;
          Toast({
            message: "数量不能小于1",
          });
          return;
        }
        this.toUpdateCart(ite);
        this.$forceUpdate();
      },
      out(ite) {
        if (ite.count < 2) {
          Toast({
            message: "数量不能小于1",
          });
          return;
        }
        ite.count--;
        this.toUpdateCart(ite);
        this.$forceUpdate();
      },
      add(ite) {
        ite.count++;
        this.toUpdateCart(ite);
        this.$forceUpdate();
      },
      toProductInfo(id) {
        this.$router.push({ path: "/info/productInfo", query: { id: id } });
      },
      noPay() {
        //取消支付
        Toast({
          message: "取消支付",
          icon: "circle",
        });
        this.$router.push({ path: "/order", query: { active: 1 } });
      },
      toUpdateCart(ite) {
        //修改购物车 产品数量
        this.$http({
          method: "post",
          url: `${this.apis.modifyCart}`,
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          data: { cartId: ite.cartId, count: ite.count },
          transformRequest: [
            function (data) {
              let ret = "";
              for (let it in data) {
                ret +=
                  encodeURIComponent(it) +
                  "=" +
                  encodeURIComponent(data[it]) +
                  "&";
              }
              ret = ret.substring(0, ret.lastIndexOf("&"));
              return ret;
            },
          ],
        }).then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.toConfirm();
          } else {
            Toast({
              message: res.message,
              icon: "fail",
            });
            return;
          }
        });
      },
      /**
       * 检查是否可购买
       */
      toConfirm() {
        let cartIds = this.carts.map((ite) => {
          return ite.cartId;
        });
        this.$loading.hide();
        if (cartIds.length > 0) {
          this.$http({
            method: "post",
            url: `${this.apis.confirm}`,
            params: { cartIds: cartIds.toString() },
          }).then((res) => {
            console.log(res);
            if (res.code && res.code == 200) {
              Toast({
                message: "修改成功",
              });
            } else {
              Toast({
                message: res.message,
                icon: "fail",
              });
              return;
            }
          });
        }
      },
      toPay(type) {
        this.$loading.show();
        this.$http({
          method: "post",
          url: "/unionpay/h5/payOrderByWap", //`${this.payUrl}`,
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          data: {
            orderNo: this.comOrder.orderNo,
            payType: type,
          },
          transformRequest: [
            function (data) {
              let ret = "";
              for (let it in data) {
                ret +=
                  encodeURIComponent(it) +
                  "=" +
                  encodeURIComponent(data[it]) +
                  "&";
              }
              ret = ret.substring(0, ret.lastIndexOf("&"));
              return ret;
            },
          ],
        }).then((res) => {
          console.log(res);
          // res.data 为接口返回的html完整文件代码
          // 必须要存进localstorage，否则会报错，显示不完全
          window.localStorage.removeItem("callbackHTML");
          window.localStorage.setItem("callbackHTML", res);
          // 读取本地保存的html数据，使用新窗口打开
          var newWin = window.open("", "_self");
          newWin.document.write(localStorage.getItem("callbackHTML"));
          // 关闭输出流
          newWin.document.close();
          if (res.code == 200) {
            this.$store.commit("savePayUrl", res.data);
            this.$router.push({ path: "/href" });
            // window.location.href = res.data
          }
        });
      },
      save() {
        if (!this.user.mobile && this.user.mobile == "") {
          Toast({
            message: "取票人信息为空！或使用“皖事通”APP使用",
            icon: "fail",
          });
          return;
        }
        this.$loading.show();
        let is = false; // 判断当前是否存在出游人列表
        this.carts.forEach((v) => {
          if (!v.orderFields.length) {
            is = false;
          } else {
            if (!v.chuyourens) {
              is = true;
            }
          }
        });
        if (is) {
          this.$loading.hide();
          Toast({
            message: "当前没有出游人 请点击新增添加出游人信息",
            icon: "fail",
          });
          return;
        } else {
          this.$loading.show();
        }
        let cartIds = this.carts.map((ite) => {
          return ite.cartId;
        });
        let appointmentStartTime = "";
        let appointmentEndTime = "";

        let isChuYouRen = true;
        let playerList = this.carts.map((ite) => {
          let playerIds = [];
          let cout = 0;
          let chuyours = 0;
          if (ite.playerRule === 0 && ite.orderFields.length > 0) {
            cout += ite.count;
          } else if (ite.orderFields.length > 0) {
            cout += 1;
          }

          ite.chuyourens &&
            ite.chuyourens.map((it) => {
              if (it.select && it.select === true) {
                chuyours += 1;
                playerIds.push(it.id);
              }
            });
          if (cout != chuyours) {
            isChuYouRen = false;
          }
          if (ite.appointment) {
            ite.appointment.map((yy) => {
              //预约时间
              if (yy.check) {
                appointmentStartTime = yy.startTime;
                appointmentEndTime = yy.endTime;
              }
            });
          }
          let obj = { cartId: ite.cartId, playerIds: playerIds.toString() };
          if (obj.playerIds == "") {
            delete obj.playerIds;
          }
          return obj;
        });
        if (!isChuYouRen) {
          this.$loading.hide();
          Toast({
            message: "出游人数量不正确！",
            icon: "fail",
          });
          return;
        }
        let cus = {
          name: this.user.name,
          mobile: this.user.mobile,
          cartId: cartIds.toString(),
        };
        let data = {
          cartIds: cartIds.toString(),
          visitDate: this.carts[0].visitDate,
          customerInfo: JSON.stringify(cus),
          playerList: JSON.stringify(playerList),
          appointmentStartTime: appointmentStartTime,
          appointmentEndTime: appointmentEndTime,
        };
        if (appointmentStartTime == "") {
          delete data.appointmentStartTime;
        }
        if (appointmentEndTime == "") {
          delete data.appointmentEndTime;
        }
        this.$http({
          method: "post",
          url: `${this.apis.submitOrder}`,
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          data: data,
          transformRequest: [
            function (data) {
              let ret = "";
              for (let it in data) {
                ret +=
                  encodeURIComponent(it) +
                  "=" +
                  encodeURIComponent(data[it]) +
                  "&";
              }
              ret = ret.substring(0, ret.lastIndexOf("&"));
              return ret;
            },
          ],
        }).then((res) => {
          this.$loading.hide();
          if (res.code == 200) {
            this.comOrder = {
              orderNo: res.orderNo,
              orderStatus: res.orderStatus,
              payAmount: res.payAmount,
            };
            if (res.orderStatus == 1) {
              //1 未支付 2 已支付
              this.getPayInfo(); //去支付
            } else {
              this.$router.push({ path: "/order", query: { active: 0 } });
            }
          } else {
            Toast({
              message: res.message,
              icon: "fail",
            });
          }
        });
      },
      getPayInfo() {
        this.$http({
          method: "post",
          url: `${this.apis.getPayInfo}`,
        }).then((res) => {
          if (res.code == 200) {
            console.log(res);
            this.isPay = true;
            this.payUrl = res.data.miniPayUrl;
          }
        });
      },
      async selectChuyouren(index, product, type) {
        if (type === "") {
          product.chuyourens[index].select = !product.chuyourens[index].select;
        } else {
          //删除按钮
          if (!product.chuyourens[index].select) {
            Toast({
              message: "未选择该出游人",
              icon: "fail",
            });
            return;
          }
          product.chuyourens[index].select = false;
          // product.chuyourens.splice(index,1);
          this.$forceUpdate();
          return;
        }
        product.playerIds = product.chuyourens.map((ite) => {
          if (ite.select && ite.select == true) return ite.id;
        });
        this.checkProduct = product;
        // if (product.chuyourens[index].select == true) {
        //   //检查安康码
        //   let data = product.chuyourens[index];
        //   if (data && data.ankang != 1) {
        //     //安康码颜色，1绿色，2黄色，3红色
        //     this.ankangs.push(data);
        //     this.showQrCode = true; //弹出健康码
        //     product.chuyourens[index].select = false;
        //   }
        //   // this.$http({
        //   //     method: "post",
        //   //     url: `${this.apis.getAnKangStatusInfo}`,
        //   //     params: {
        //   //         userName: user.playerName,
        //   //         idcardNo:user.idcardNumber,
        //   //         isSales:0
        //   //     }
        //   // }).then((res) => {
        //   //     if (res.code == 200) {
        //   //       let data = JSON.parse(res.data)
        //   //       if(data.data != null && data.data.healthLevel != 1){ //安康码颜色，1绿色，2黄色，3红色
        //   //         this.ankangs = data.data
        //   //         this.showQrCode = true //弹出健康码
        //   //         product.chuyourens[index].select = false;
        //   //       }
        //   //     }
        //   // })
        // }
        this.$forceUpdate();
      },
      toCheckTime(ite, index) {
        ite.map((it) => {
          it.check = false;
        });
        ite[index].check = true;
        this.$forceUpdate();
      },
      onConfirm(date) {
        //确认游玩日期
        if (this.isAppointment && this.isAppointment != "false") {
          //预约票
          this.showDate = false;
          this.checkProduct.visitDate = this.dateFormat(date, "yyyy-MM-dd");
          this.getTimes();
        } else {
          this.canDate = false;
          this.checkProduct.visitDate = this.dateFormat(date, "yyyy-MM-dd");
        }
      },
      getTimes() {
        this.carts.map((ite) => {
          this.times.map((it) => {
            if (
              it.playDate == ite.visitDate &&
              this.isAppointment &&
              this.isAppointment != "false"
            ) {
              ite.appointment = it.appointment;
            }
          });
        });
        console.log(this.carts);
        this.$forceUpdate();
      },
      toEditChuyouren(id) {
        this.$router.push({
          path: "/personal/addChuyouren",
          query: { id: id },
        });
      },
      getChuyouren() {
        this.$http({
          method: "post",
          url: `${this.apis.playerList}`,
          params: {
            memberId: this.user.id,
            organizationId: this.organizationId,
          },
        }).then((res) => {
          if (res.code == 200) {
            this.chuyourens = res.data;
            let players = res.data;
            let op = 0;
            players.map((ite) => {
              op++;
              console.log(ite);
              if (op == players.length) {
                this.carts.map((v) => {
                  v.chuyourens = JSON.parse(JSON.stringify(players)); //默认写入所有出游人 select判断是否选择
                  //v.visitDate = this.dateFormat(new Date(),'yyyy-MM-dd');//默认游玩日期 当天
                });
                console.log(this.carts);
                this.monthShow(new Date());
                this.$forceUpdate();
                this.showcChuyourens = JSON.parse(JSON.stringify(players));
              }
              // this.$http({
              //   //遍历出游人获取安康码
              //   method: "post",
              //   url: `${this.apis.getAnKangStatusInfo}`,
              //   params: {
              //     userName: ite.playerName,
              //     idcardNo: ite.idcardNumber,
              //     isSales: 0,
              //     orgId:localStorage.getItem('ordId')
              //   },
              // }).then((res) => {
              //   if (res.code == 200) {
              //     let data = JSON.parse(res.data);
              //     ite.ankang = 1;
              //     if (data.data != null && data.data.healthLevel != 1) {
              //       //安康码颜色，1绿色，2黄色，3红色
              //       ite.ankang = data.data.healthLevel;
              //     }
              //     if (op == players.length) {
              //       this.carts.map((ite) => {
              //         ite.chuyourens = JSON.parse(JSON.stringify(players)); //默认写入所有出游人 select判断是否选择
              //         //ite.visitDate = this.dateFormat(new Date(),'yyyy-MM-dd');//默认游玩日期 当天
              //       });
              //       console.log(this.carts);
              //       this.monthShow(new Date());
              //       this.$forceUpdate();
              //       this.showcChuyourens = JSON.parse(JSON.stringify(players));
              //     }
              //   }
              // });
            });
          }
        });
      },
      toShowDate(ite) {
        if (
          this.carts.length === 1 &&
          ite.isChoosePlayDate == true &&
          this.isAppointment &&
          this.isAppointment != "false"
        ) {
          //只有一张票和可选日期&预约票
          this.showDate = true;
        } else if (this.carts.length === 1 && ite.isChoosePlayDate == true) {
          //只有一张票和可选日期 正常可选日期票
          this.minDate = new Date(this.order.validStartDate);
          this.maxDate = new Date(this.order.validEndDate);
          this.canDate = true;
        }
        this.checkProduct = ite;
      },
      monthShow(ite) {
        if (this.isAppointment && this.isAppointment != "false") {
          //预约票
          let startDate = this.dateFormat(ite, "yyyy-MM-dd");
          if (ite.getMonth() + 1 === new Date().getMonth() + 1) {
            startDate = this.dateFormat(new Date(), "yyyy-MM-dd");
          }
          let endDate = this.getCurrentMonthLast(startDate);
          this.carts.forEach((ite) => {
            //获取预约日期余票
            this.$http({
              method: "post",
              url: `${this.apis.getDayProduct}`,
              params: {
                productId: ite.productId,
                startDate: startDate,
                endDate: endDate,
              },
            }).then((res) => {
              if (res.code == 200) {
                console.log(res);
                this.times = this.times.concat(res.data);
                this.getTimes();
              }
            });
          });
        }
      },
      formatter(day) {
        //日期组件定义余票
        if (this.isAppointment && this.isAppointment != "false") {
          //预约票
          const month = day.date.getMonth() + 1;
          const date = day.date.getDate();
          this.times.forEach((ite) => {
            if (ite.appointment.length > 0) {
              //可预约
              let visitDate = new Date(ite.visitDate);
              let mon = visitDate.getMonth() + 1;
              let myday = visitDate.getDate();
              let stock = 0; //剩余量
              ite.appointment.map((li) => {
                stock += li.stock - li.saledStock;
              });
              stock = stock + "";
              stock = stock.substring(0, 3) + ".";
              if (month === mon) {
                if (date === myday) {
                  day.bottomInfo = "剩余" + stock;
                }
              }
            } else {
              //无票
              day.bottomInfo = "无票";
              day.type = "disabled";
            }
          });
        }
        // else{

        // }
        return day;
      },
      formatDate(date) {
        let month = date.getMonth() + 1;
        let day = date.getDate();
        if (month < 10) {
          month = "0" + month;
        }
        if (day < 10) {
          day = "0" + day;
        }
        return `${date.getFullYear()}-${month}-${day}`;
      },
      getWeek(dateString, str) {
        let week = new Date(dateString).getDay() + 1;
        if (str.include(week)) {
          return true;
        }
      },
      dateFormat,
      getCurrentMonthLast,
    },
  };
</script>
<style lang="less" scoped>
  .wrap {
    background-color: hsl(0deg 0% 96% / 90%);
    width: 97%;
    margin: 0 auto;
    .cart-list {
      padding-bottom: 4px;
      border-bottom: 1px solid rgb(239 239 239);
    }
  }
  .del-price {
    text-decoration: line-through;
    color: #969799;
    font-size: 15px;
  }
  .cart-line1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    color: #646566;
    .inp {
      text-align: center;
      width: 25%;
      border: none;
      background-color: hsl(0deg 0% 100% / 90%);
    }
  }
  .visit-date {
    border: 1px solid #ef8669;
    padding: 8px 35px;
    float: right;
  }
  .order-price {
    margin-top: 10px;
    padding: 16px;
    background-color: white;
    display: flex;
    justify-content: space-between;
  }
  .refun {
    color: #fa7134;
    border: 1px solid #ff976a;
    border-radius: 15px;
    padding: 2px 5px;
  }
  .ankangma {
    font-size: 40px;
  }
  .ankang-info {
    display: flex;
    flex-direction: column;
    font-size: 40px;
  }
  .buy-user {
    margin-top: 10px;
    padding: 16px;
    background-color: white;
  }
  .refunPrice {
    color: #fa7134;
    border: 1px solid #ff976a;
    border-radius: 15px;
    padding: 2px 5px;
    margin-left: 5px;
  }
  .hasStock {
    border: 1px solid #ff976a;
    padding: 8px 35px;
    padding: 5px 1px;
    font-size: 12px;
  }
  .chuyouren-edit {
    font-weight: bold;
    margin-top: 130%;
    float: right;
    color: #ef8669;
  }
  .chuyouren-wrap {
    padding: 16px;
    background-color: white;
    color: #666666;
  }
  .chuyouname {
    border: 1px solid #c8c9cc;
    color: #666666;
    padding: 8px 15px;
    margin-left: 3px;
    position: relative;
  }
  .selectChuyouname {
    border: 1px solid #ef8669;
    padding: 8px 15px;
    margin-left: 3px;
    position: relative;
  }

  .selectChuyouname:before {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    border: 6px solid #ff6034;
  }
  .selectChuyouname:after {
    content: "";
    width: 2px;
    height: 8px;
    position: absolute;
    right: 2px;
    bottom: 1px;
    border: 2px solid #fff;
    border-top-color: transparent;
    border-left-color: transparent;
    transform: rotate(45deg);
  }
</style>
