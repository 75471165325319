export function formatDate (date) {
  let month = date.getMonth() + 1;
  let day = date.getDate();
  if (month < 10) {
    month = '0' + month
  }
  if (day < 10) {
    day = '0' + day
  }
  return `${date.getFullYear()}-${month}-${day}`;
}
export function dateFormat (date, format) {
  date = new Date(date);
  date.setHours(date.getHours());
  var o = {
    'M+': date.getMonth() + 1, //month
    'd+': date.getDate(), //day
    'H+': date.getHours(), //hour
    'm+': date.getMinutes(), //minute
    's+': date.getSeconds(), //second
    'q+': Math.floor((date.getMonth() + 3) / 3), //quarter
    'S': date.getMilliseconds() //millisecond
  };

  if (/(y+)/.test(format))
    format = format.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));

  for (var k in o)
    if (new RegExp('(' + k + ')').test(format)) {
      format = format.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length));
    }
  return format;
}

/**
 * 获取当前月的最后一天
 * @returns {Date}
 */
export function getCurrentMonthLast (d) {
  var date = new Date(d);
  var currentMonth = date.getMonth();
  var nextMonth = ++currentMonth;
  var nextMonthFirstDay = new Date(date.getFullYear(), nextMonth, 1);
  var oneDay = 1000 * 60 * 60 * 24;
  var lastTime = new Date(nextMonthFirstDay - oneDay);
  var month = parseInt(lastTime.getMonth() + 1);
  var day = lastTime.getDate();
  if (month < 10) {
    month = '0' + month
  }
  if (day < 10) {
    day = '0' + day
  }
  return date.getFullYear() + '-' + month + '-' + day;
}
// 时间挫转时分秒
export const numberToDate = (num) => {
  let day = plug(Math.trunc(num / (3600 * 24 * 1000)));
  let h = plug(Math.trunc((num - 3600 * 24 * 1000 * day) / (3600 * 1000)));
  let m = plug(Math.trunc((num - 3600 * 24 * 1000 * day - 3600 * 1000 * h) / (60 * 1000)));
  let s = plug(Math.trunc((num - 3600 * 24 * 1000 * day - 3600 * 1000 * h - 60 * 1000 * m) / 1000));
  return {
    day: day,
    h: h,
    m: m,
    s: s
  }
}
function plug (str) {
  if (str.toString().length == 1) {
    return `0${str}`;
  } else {
    return str;
  }
}
// 格式化 文字展示 中间自定义字符  目前写死 前三位展示 后三位展示
export const privacyStr = (value, str = '*') => {
  if (value === null || value.length === '') {
    return ''
  } else {
    var len = 0
    var val = ''
    len = Number(value.length - 6)
    for (let i = 0; i < len; i++) {
      val += str
    }
    value = value.substr(0, 3) + val + value.substr(value.length - 3)
    return value;
  }
};
export const privacyName = (value) => {
  let star = ""
  if (null != value && value != undefined) {
    //名字是两位的就取姓名首位+*
    if (value.length <= 2) {
      return value.substring(0, 1) + "*";
    } else {
      for (var i = 0; i < value.length - 2; i++) {
        star = star + '*'
      }
      return value.substring(0, 1) + star + value.substring(value.length - 1, value.length);
    }
  }
};